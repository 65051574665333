export class QueDich {
    stt: Number;
    cung: String;
    hanhCung: String;
    ten:String;
    que: String;
    tenQue: String;
    lucThan: String;
    diaChi: String;
    hanh: String;
    pointUng: Number;
    pointThe: Number;
  }