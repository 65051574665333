import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueDich } from './../models/queDich.model';
import { HttpHeaders } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class QueDichService {
    private readonly API_URL = "https://bd-be.gieoquedich.com/";
    constructor(private http: HttpClient) { }

    getQueDich() {
        return this.http.get<QueDich[]>(this.API_URL + 'queDich');
    }
    predict(hao) {
        let param = {
                "que": hao.que,
                "isBien": hao.isBien,
                "indexBien": hao.indexBien,
                "haoGoc": hao.haogoc
        };
        return this.http.post(this.API_URL +'academic', {queDich: param});
    }
}