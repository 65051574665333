import { Component, OnInit } from '@angular/core';
import { QueDichService } from './../services/que.service';
import Utils from './../helpers/utils';
import { KetQua } from './../models/ketQua.model'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public dateNow: Date = new Date();
  public lunarDate;
  public txtLunaDate;
  hao: String;
  haoBien: String;
  lableGieo: String;
  showPredict = true;
  showResult = false;
  showReset = false;
  isLuanQue = false;
  matHao = 0;
  arrGieo = [2, 2, 2, 2, 2, 2, 2];
  arrHaoBien: Array<Number>;
  indexHaoBien;
  txtBtnPredict: String;
  luanQue;
  luanQueBien;
  viTriHaoBien;
  arrBangKetQua: Array<any>;
  arrBangKetQuaBien: Array<any>;
  lucThan: Array<String>;
  hienKetQua = false;
  count = 0;
  arrIndexBien =[];

  constructor(private queDichSevice: QueDichService) {
    this.hao = '';
    this.haoBien = '';
    this.arrHaoBien = [2, 2, 2, 2, 2, 2, 2];
    this.indexHaoBien = [2, 2, 2, 2, 2, 2, 2];
    this.viTriHaoBien = [2, 2, 2, 2, 2, 2, 2];
    this.lableGieo = "Gieo lần 1";
    this.arrBangKetQua = new Array<any>();
    this.arrBangKetQuaBien = new Array<any>();
    setInterval(() => {
      this.dateNow = new Date();
      let now = new Date();
      let lunaDate = Utils.convertSolar2Lunar(this.dateNow.getDate(), (this.dateNow.getMonth() + 1), this.dateNow.getFullYear(), 7);
      this.lunarDate = lunaDate[0] + '/' + lunaDate[1] + '/' + lunaDate[2];
      this.txtLunaDate = "Ngày " + Utils.canChiDay(this.dateNow.getDate(), (this.dateNow.getMonth() + 1), this.dateNow.getFullYear()) + ", tháng "
        + Utils.canChiMonth(lunaDate[1], lunaDate[2]) + ", năm " + Utils.canChiYear(lunaDate[2]);
    }, 1);

    setInterval(() => {

    }, 5000);
  }

  ngOnInit() {

  }

  onToss() {
    this.count++;
    let xu1 = Math.random() < 0.5 ? 0 : 1;
    let xu2 = Math.random() < 0.5 ? 0 : 1;
    let xu3 = Math.random() < 0.5 ? 0 : 1;
    let haoAmDuong = Utils.tinhHao(xu1, xu2, xu3);

    if (haoAmDuong === -2) {
      this.hao += '1';
      this.haoBien += '0';
      this.arrGieo[this.count - 1] = 1;
      this.arrHaoBien[this.count - 1] = 0;
      this.indexHaoBien[this.count - 1] = 0;
      this.viTriHaoBien[this.count - 1] = 0;
      this.arrIndexBien.push(this.count-1);
    }
    else if (haoAmDuong == 2) {
      this.hao += '0';
      this.haoBien += '1';
      this.arrGieo[this.count - 1] = 0;
      this.arrHaoBien[this.count - 1] = 1;
      this.indexHaoBien[this.count - 1] = 1;
      this.viTriHaoBien[this.count - 1] = 0;
      this.arrIndexBien.push(this.count-1);
    }
    else {
      this.hao += haoAmDuong;
      this.haoBien += haoAmDuong;
      this.arrGieo[this.count - 1] = haoAmDuong;
      this.arrHaoBien[this.count - 1] = haoAmDuong;
    }
    this.lableGieo = "Gieo lần " + (this.count + 1);
    if (this.count >= 6) {
      this.showResult = true;
      this.showPredict = false;
      this.viTriHaoBien = this.viTriHaoBien.reverse();
    }
  }
  onPredict() {
    this.showResult = false;
    this.queDichSevice.predict({
      "que": this.hao,
      "isBien": false,
    }).subscribe(data => {
        this.hienKetQua = true;
        this.luanQue = data;
        let diem = this.luanQue.diem;
        let arrLucThan = this.luanQue.lucThan;
        let arrDiaChi = this.luanQue.diaChi;
        let arrNguHanh = this.luanQue.hanh;
        let arrTruongSinh = this.luanQue.arrTruongSinh;
        let arrQueThe = this.luanQue.arrQueThe;
        let arrVTQueGoc = this.luanQue.arrVTQueGoc;
        let arrVTHao = this.luanQue.arrVTHao;


        for (let i = 0; i < arrLucThan.length; i++) {
          let nguHanh = "";
          if (i === this.luanQue.pointUng) {
            nguHanh = i === this.luanQue.pointUng ? arrNguHanh[i] + " (Ứng)" : arrNguHanh[i];
          } else {
            nguHanh = i === this.luanQue.pointThe ? arrNguHanh[i] + " (Thế)" : arrNguHanh[i];
          }
          var item = {
            lucThan: arrLucThan[i],
            diaChi: arrDiaChi[i],
            nguHanh: nguHanh,
            arrQueThe: arrQueThe.length>0? arrQueThe[i]: "",
            diem: diem[i] + " " + arrTruongSinh[i],
            queGoc: arrVTQueGoc[i],
            vtQue: arrVTHao[i]
          }
          this.arrBangKetQua.push(item);
        }
        this.arrBangKetQua.reverse();
        console.log( this.arrBangKetQua);
        this.showReset = true;
        this.isLuanQue = true;
      });

    if (this.hao !== this.haoBien) {
      this.queDichSevice.predict({
        "que": this.haoBien,
        "isBien": true,
        "indexBien": this.arrIndexBien,
        "haogoc": this.hao
      }).subscribe(data => {
        this.luanQueBien = data;
        let diem = this.luanQueBien.diem;
        let arrLucThan = this.luanQueBien.lucThan;
        let arrDiaChi = this.luanQueBien.diaChi;
        let arrNguHanh = this.luanQueBien.hanh;
        let arrTruongSinh = this.luanQueBien.arrTruongSinh;
        let arrVTQueGoc = this.luanQueBien.arrVTQueGoc;
        let arrVTHao = this.luanQueBien.arrVTHao;
        let arrHanhBien = this.luanQueBien.arrHanhBien;
        let arrVtHanhBien = this.luanQueBien.arrVtHanhBien;

        for (let i = 0; i < arrLucThan.length; i++) {
          let nguHanh = "";
          if (i === this.luanQueBien.pointUng) {
            nguHanh = i === this.luanQueBien.pointUng ? arrNguHanh[i] + " (Ứng)" : arrNguHanh[i];
          } else {
            nguHanh = i === this.luanQueBien.pointThe ? arrNguHanh[i] + " (Thế)" : arrNguHanh[i];
          }
          var item = {
            lucThan: arrLucThan[i],
            diaChi: arrDiaChi[i],
            nguHanh: nguHanh,
            hanh: arrNguHanh[i],
            diem: diem[i] + " " + arrTruongSinh[i],
            queGoc: arrVTQueGoc[i],
            vtQue: arrVTHao[i],
            hanhBien : arrHanhBien[i],
            vtHanhBien: arrVtHanhBien[i]===''?'|': arrVtHanhBien[i]
          }
          this.arrBangKetQuaBien.push(item);
        }
        this.arrBangKetQuaBien.reverse();
      })
    }
  }
  onReset() {
    this.hienKetQua = false;
    this.hao = '';
    this.haoBien = '';
    this.count = 0;
    this.showResult = false;
    this.showPredict = true;
    this.showReset = false;
    this.isLuanQue = false;
    this.lableGieo = "Gieo lần 1";
    this.arrBangKetQua = [];
    this.indexHaoBien = [2, 2, 2, 2, 2, 2, 2];
    this.viTriHaoBien = [2, 2, 2, 2, 2, 2, 2];
    this.arrBangKetQuaBien = [];
    this.arrIndexBien =[];
  }
}
